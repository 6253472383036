@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.ModalFooterCtn {
    background: #F7F9FA;
    border: #E4E8EC 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 26px;
  }
  .ModalFooterCtn .btn{
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    text-transform: uppercase;
    height: 38px;
    box-shadow: none;
  }
  
  .ModalFooterCtn .btnCencel{
    background: #fff;
    color: #4C5E67;
    border: #E4E8EC 1px solid;
  }
  
  .ModalFooterCtn .btnCencel:hover{
    background: #fff;
    color: #4C5E67;
    border: #E4E8EC 1px solid;
  }
  
  .ModalFooterCtn .btnCencel:focus{
    background: #fff;
    color: #4C5E67;
    border: #E4E8EC 1px solid;
  }
  
  
  .ModalFooterCtn .btnDelete{
    background: #FF0033;
    color: #fff;
    border: #FF0033 1px solid;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .ModalFooterCtn .btnDelete:hover{
    background: #FF0033;
    border: #FF0033 1px solid;
    color: #fff;
  }
  
  .ModalFooterCtn .btnDelete:focus{
    background: #FF0033;
    border: #FF0033 1px solid;
    color: #fff;
  }
  
  .DeleteModalBox {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 540px;
    border-radius: 4px;
}
  
  .DltCmpMdlBody{
    padding: 26px;
  }
  
  .DeleteModalBoxInner{
    position: relative;
  }

  .FldIconCtn{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
.FldIconCtn .closeIcon {
    color: #9593b3;
    position: absolute;
    right: 10px;
    cursor: pointer;
    top: 11px;
  }
  .DeleteCampCtn h2{
    text-align: center;
    font-size: 24px;
    color: #1B2121;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400;
  }
  
  .DeleteCampCtn p{
    text-align: center;
    font-size: 14px;
    color: #4C5E67;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
  }

  .FldIconCtn h2{
    font-size: 14px;
    font-weight: 400;
    color: #676C7B;
    margin: 0;
  }

  .borderBottom {
    border-bottom: #E4E8EC 1px solid;
    padding: 14px 20px;
}

.orderModalCampCtn p{ 
  text-align: left;
    font-size: 14px;
    color: #0B132B;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    margin: 0;
}

.ModalOrderFooterCtn {
  justify-content: end;
  align-items: center;
  border-radius: 0 0 4px 4px;
}

.ModalOrderFooterCtn .btn{
  margin-left: 10px;
}

.btn.btnconfirm{
  background: #33B651;
  color: #fff;
}

.btn.btnconfirm:hover{
  background: #33B651;
  color: #fff;
}


.btn.btnclose{
  background: #fff;
  color: #676C7B;
  border: #E4E8EC 1px solid;
}

.btn.btnclose:hover{
  background: #fff;
  color: #676C7B;
  border: #E4E8EC 1px solid;
}

iframe{
  position: relative !important;
}

.ModalFormCtn{
  margin-top: 24px;
  margin-bottom: 15px;
}

.FormGroup{
  display: flex;
  align-items:center;
}

.FormGroup .amountSign {
  border: #E4E8EC 1px solid;
  height: 56px;
  width: 78px;
  border-radius: 4px 0 0 4px;
  position: relative;
  right: -2px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0B132B;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif !important;
}

.Fieldfill .amountSign{
  background:#F7F9FA;
  color: #676C7B;
}

.Fieldfill .amountSign{
  background:#F7F9FA;
  color: #676C7B;
}

.Fieldfill .input_container .MuiOutlinedInput-input{
  background:#F7F9FA;
}

.Fieldfill .MuiOutlinedInput-adornedEnd {
  background: #F7F9FA;
  border: #e7e7e7 1px solid;
}

.Fieldfill fieldset {
  display: none !important;
}



.AlertCtnSuccess{
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
  width: 100%;
}

.AlertCtnSuccess .successAlert{
  position: relative;
  width: 500px;
  margin: 0 auto;
  margin-top: 15px;
  background-color: #33B651 !important;
  color: #fff !important;

}

.successAlert svg{
  color: #fff !important;

}

.AlertCtnError {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
  width: 100%;
}

.AlertCtnError .MuiAlert-icon{
  display: none;
}

.AlertCtnError .successAlert{
  position: relative;
  width: 500px;
  margin: 0 auto;
  margin-top: 15px;
  background-color: #db1608 !important;
  color: #fff !important;

}


