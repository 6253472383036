.OrderSummaryCtn {
    position: relative
}



.OrderSummaryCtn .OrderSummaryTitle {
    color: #4C5E67;
    font-size: 16;
    border-bottom: #B0B9C1 1px solid;
    padding-bottom: 8;
    font-family: 'Poppins', sans-serif;
}

.OrderSummaryCtn .itemContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
}

.OrderSummaryCtn .itemListing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
}

.OrderSummaryCtn .itemText {
    color: #1B2121;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    width:auto;
}

.OrderSummaryCtn .editButton {
    color: #0590FA;
    font-size: 14px;
    font-weight: 600;
    text-transform: initial;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

.OrderSummaryCtn .itemListText{
    color: #0B132B;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}
.OrderSummaryCtn .itemAmountText {
    color: #0B132B;
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    font-family: 'Poppins', sans-serif;
}

.OrderSummaryCtn .itemListContainer{
    margin-top: 20px
}

.OrderSummaryCtn .itemBlueText{
    color: #0590FA;
}

.OrderSummaryCtn .infoIcon{
    color: #B0B9C1;
    position: relative;
    top: 7px;
    left: 10px;
}

.OrderSummaryCtn .FieldContainer{
    position: relative;
}

.OrderSummaryCtn .TextField{}

.OrderSummaryCtn .ButtonApply{
    color: #0590FA;
    font-size: 14px;
    font-weight: 600;
    text-transform: initial;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    position: absolute;
    right:8px;
    top:15px;

   
}

.OrderSummaryCtn .ButtonApply:hover{
    background-color:transparent;
  }


  .OrderSummaryCtn .TotalContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:32px;
    padding-top:20px;
    border-top: #E4E8EC 1px solid;
}

.OrderSummaryCtn .TotalText {
    color: #1B2121;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.OrderSummaryCtn .TotalAmount{
    color: #1B2121;
    font-size: 20px;
    font-weight: 500;
    text-align: right;
    font-family: 'Poppins', sans-serif;
}

.OrderSummaryCtn .OrderSummaryProductList{
    border-bottom: #E4E8EC 1px solid;
}

.OrderSummaryCtn .OrderSummaryProductList li{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.OrderSummaryCtn .OrderSummaryProductList li .ProductListleft{
    display: flex;
    align-items: center;
}

.OrderSummaryCtn .OrderSummaryProductList li .ProductListleftimg{
    width: 56px;
    height: 56px;
    overflow: hidden;
}

.OrderSummaryCtn .OrderSummaryProductList li .ProductListleftimg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.OrderSummaryCtn .OrderSummaryProductList li .ProductListleftInfo{
    margin-left: 8px;
}

.OrderSummaryCtn .OrderSummaryProductList li .ProductListleftInfo h2{
    margin: 0;
    color: #1B2121;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;

}

.OrderSummaryCtn .OrderSummaryProductList li .ProductListleftInfo p{
    margin: 0;
    color: #676C7B;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;

}

.ProductListRight{}


