@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.FormBody .MuiContainer-maxWidthMd {
  max-width: 730px;
}

.StapperMainContainer {
}

.StapperMainContainer .StepperHeader {
  background: #f7f9fa;
  padding: 0 26px;
}

.StapperMainContainer .StepperHeader .MuiPaper-root {
  background-color: transparent;
  max-width: 550px;
  margin: 0 auto;
}

.StapperMainContainer .StepperHeader .MuiStepIcon-root.MuiStepIcon-active {
  color: #56b149;
  width: 29px;
}

.StapperMainContainer .StepperHeader .MuiStepIcon-root.MuiStepIcon-completed {
  color: #56b149;
  font-size: 29px;
}

.StapperMainContainer .StepperHeader .MuiStepLabel-label {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins" !important;
}

.StapperMainContainer .completed {
  display: inline-block;
}

.StapperMainContainer .FormBody {
  margin: 0 auto;
  padding: 0 30px;
  padding-bottom: 30px;
  margin-top: 32px;
  position: relative;
  padding-bottom: 170px;
}

.StapperMainContainer .FormButtonBody {
  margin-top: 20px;
  border-top: #e4e8ec 1px solid;
  padding: 24px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background: #fff;
  display: flex;
  justify-content: center;
}

.StapperMainContainer .FormButtonBody .MuiButton-containedPrimary {
  color: #fff;
  background-color: #56b149;
  height: 50px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  border-radius: 4px;
  padding: 0 15px;
  width: 100%;
  max-width: 710px;
}

.StapperMainContainer .FormButtonBody .MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.StapperMainContainer .FormButtonBody .buttonBack {
  border: #e4e8ec 1px solid !important;
  height: 50px;
  font-size: 14px !important;
  font-weight: 500;
  font-family: "Poppins";
  border-radius: 4px !important;
  color: #2490f9 !important;
  padding: 0 15px !important;
}

.StapperMainContainer .FormButtonBody .buttonBack .MuiSvgIcon-root {
  font-size: 16px !important;
  margin-right: 4px;
}

.AccordionTitleLeft {
  display: flex;
  align-items: center;
}

.HeaderOneMainContainer .HeaderOneContainer .HeaderOnesiteLogo a {
  max-width: initial !important;
  height: 56px;
}

.PageTitleOneMainContainer .PageTitleOneTextContainer .PageSubTitleOne {
  font-size: 26px !important;
}

.PageTitleOneMainContainer .PageTitleOneTextContainer .PageTitleOneMain {
  text-transform: initial !important;
}

.formTitle {
  font-family: "Poppins", sans-serif;
  color: #0b132b;
  font-weight: 700;
  font-size: 34px;
}

.formSubTitle {
  font-family: "Poppins", sans-serif;
  color: #0b132b;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  margin: 0;
}

.packageCardContainer .DayText {
  font-size: 14px !important;
  color: #0b132b;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif;
}

.packageCardContainer .MuiCardContent-root {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.packageCardContainer .MuiCardContent-root svg{
    color: #56B149 !important;
}



.FromList{
    margin-left: 15px;
} 

.FromList ul {
  margin: 0;
  padding: 0;
}
.FromList ul li {
  font-size: 16px;
  color: #0b132b;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin-bottom: 12px;
  display: block;
}

.FromList ul li::before {
  content: "\2022";
  color: #56b149;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.InfoText {
  background: #fff;
}

.InfoText li {
  display: flex;
  align-items: flex-start;
}

.InfoText p {
  font-family: "Poppins", sans-serif !important;
  color: #676c7b;
  font-size: 12px;
  font-weight: 400;
  margin: 0 !important;
}

.InfoText p span {
  font-weight: 600;
}

.InfoText li svg {
  color: #e4e8ec;
  margin-right: 12px;
  position: relative;
  top: 3px;
}

.center {
  text-align: center !important;
}

/* ====== Accodion === */

.AccordionContianer {
}

.AccordionContianer .MuiAccordion-root {
  background: #edf6ff;
}

.AccordionContianer .MuiAccordionDetails-root {
  padding: 32px;
  background: #fff;
  display: block;
  border-radius: 0 0 4px 4px;
}

.AccordionContianer .MuiAccordion-root.Mui-expanded {
}

.AccordionContianer .MuiAccordion-root .checkIcon {
  color: #d2d3d8;
  margin-right: 12px;
}

.AccordionContianer .MuiAccordion-root.Mui-expanded .checkIcon {
  color: #2a8853;
}

.AccordionContianer .aAccordionTitle {
  font-size: 20px;
  color: #8d9090;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  flex-basis: 100%;
  font-family: "Poppins", sans-serif;
  justify-content: space-between;
}

.AccordionContianer .aAccordionTitle span {
  font-size: 14px;
  color: #8d9090;
  letter-spacing: 0;
  display: flex;
  border: #ccc 1px solid;
  width: 28px;
  height: 28px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.AccordionContianer .Mui-expanded .aAccordionTitle {
  color: #1b2121;
}

.AccordionContianer .Mui-expanded .aAccordionTitle span {
  color: #1b2121;
}

.AccordionContianer .aAccordionTitle h3 {
  margin: 0;
  color: #1b2121;
  font-size: 20px;
  font-weight: 500;
}

.AccordionContianer .aAccordionTitle a {
  color: #0590fa;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

/* ====== Accodion End === */

.QuantityField {
  height: 48px;
  border: #c6cbcf 1px solid;
  border-radius: 4px;
  width: 90%;
  padding: 0 20px;
}

.subtotalCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.subtotalCont p {
  color: #0b132b;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.TextBtnBlue {
  color: #2490f9 !important;
  text-transform: initial !important;
  padding: 0 !important;
}

.TextBtnBlue:hover {
  background: none !important;
  background-color: none !important;
}

.secDevider {
  background: #e4e8ec;
  height: 1px;
}

.AddmoreBtn {
  padding-top: 20px;
}

.FormDetailsContainer {
}

.FormDetailsContainer .FormDetailsContainerHdr {
  display: flex;
  justify-content: space-between;
}

.FormDetailsContainer .FormDetailsContainerHdr h2 {
  font-family: "Poppins", sans-serif !important;
  color: #0b132b;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  margin-top: 4px;
}

.FormDetailsContainer .FormDetailsContainerHdr .atnEdit {
  font-family: "Poppins", sans-serif !important;
  color: #0590fa;
  font-size: 14px;
  font-weight: 500;
}

.FormDetailsContainer .FormDetailsContainerHdr .atnDelete {
  font-family: "Poppins", sans-serif !important;
  color: #0b132b;
  font-size: 14px;
  font-weight: 500;
}

.FormDetailsContainerDtl {
}

.FormDetailsContainerDtl h3 {
  font-family: "Poppins", sans-serif !important;
  color: #0b132b;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.FormDetailsContainerDtl p {
  font-family: "Poppins", sans-serif !important;
  color: #676c7b;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 4px !important;
}

.OrderDtllst {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: #e4e8ec 1px solid;
  padding: 0px 0 !important;
}

.OrderDtllst:first-child {
  padding-top: 0;
}

.OrderDtllst:last-child {
  border-bottom: none;
}

.OrderDtllst li {
  display: flex;
  color: #0b132b;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.OrderDtllst li span {
  font-weight: 700;
  margin-left: 3px;
}
.OrderDtllst li a {
  color: #2490f9;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}
.OrderDtllst li a svg {
  color: #2490f9;
  position: relative;
  top: 5px;
}

.OrderDtllst li a:hover {
  text-decoration: underline;
}

.Delivery .deleverdOptionDetails {
  border: #e4e8ec 1px solid;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Delivery .deleverdOptionDetails .deleverdOptionDetailsTitle {
  color: #4c5e67;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
  opacity: 0.6;
}

.Delivery .deleverdOptionDetails .deleverdOptionDetailsAddress {
  color: #1b2121;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.Delivery .deleverdOptionDetails .deleverdOptionDetailsPhoneNumber {
  color: #1b2121;
  font-size: 14px;
  font-weight: 500;
}

.Delivery .deleverdOptionDetails .deleverdOptionDetailsRightText {
  color: #b0b9c1;
  font-size: 12px;
  font-weight: 400;
}

.OrderDtllst {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: #e4e8ec 1px solid;
  padding: 15px 0;
}

.OrderDtllst:first-child {
  padding-top: 0;
}

.OrderDtllst:last-child {
  border-bottom: none;
}

.OrderDtllst li {
  display: flex;
  color: #0b132b;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.OrderDtllst li span {
  font-weight: 700;
  margin-left: 3px;
}
.OrderDtllst li a {
  color: #2490f9;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
}
.OrderDtllst li a svg {
  color: #2490f9;
  position: relative;
  top: 5px;
}

.OrderDtllst li a:hover {
  text-decoration: underline;
}

.CpnTitle {
  color: #0b132b;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16px;
}

.CopnField {
  position: relative;
}

.errorIcon {
  font-size: 13px !important;
  position: relative;
  top: 2px;
}

.CopnField .applyBtn {
  position: absolute;
  right: 7px;
  top: 16px;
}

.CopnField .applyBtn .cencelBtn {
  position: absolute;
  right: -76px;
}

.CopnField .applyBtn .MuiButton-outlined {
  border: none;
  padding: 0;
  color: #0590fa;
  font-size: 14px;
  text-transform: initial;
}

.CopnField .applyBtn .MuiButton-root.Mui-disabled {
  background: none !important;
  color: #b0b9c1;
}

.loader .MuiCircularProgress-colorPrimary {
  color: #0590fa;
  position: absolute;
  top: -7px;
  right: 12px;
  width: 30px !important;
  height: 30px !important;
}

.amountIcon {
  color: #2a8853;
  font-size: 22px;
  position: relative;
  top: 1px;
  left: 4px;
}

.OderDetailsCtn {
  padding-bottom: 0px;
}

.FormBtn {
  padding-top: 30px;
}

.FormDetailsContainer {
}

.FormDetailsContainer .FormDetailsContainerHdr {
  display: flex;
  justify-content: space-between;
}

.FormDetailsContainer .FormDetailsContainerHdr h2 {
  font-family: "Poppins", sans-serif !important;
  color: #0b132b;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  margin-top: 4px;
}

.FormDetailsContainer .FormDetailsContainerHdr .atnEdit {
  font-family: "Poppins", sans-serif !important;
  color: #0590fa;
  font-size: 14px;
  font-weight: 500;
}

.FormDetailsContainer .FormDetailsContainerHdr .atnDelete {
  font-family: "Poppins", sans-serif !important;
  color: #0b132b;
  font-size: 14px;
  font-weight: 500;
}

.FormDetailsContainerDtl {
}

.FormDetailsContainerDtl h3 {
  font-family: "Poppins", sans-serif !important;
  color: #0b132b;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.FormDetailsContainerDtl p {
  font-family: "Poppins", sans-serif !important;
  color: #676c7b;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 4px;
}

.formSectionTitleIn {
  font-family: "Poppins", sans-serif !important;
  color: #0b132b;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.cvvICon {
  position: absolute;
  right: 10px;
  top: 40px;
}

.field_box {
  position: relative;
}

.MuiDrawer-paperAnchorRight {
  left: auto;
  right: 0;
  width: 420px;
}

.DrawerBody {
  padding: 26px 64px;
}

.DrawerTitel {
  border-bottom: #e4e8ec 1px solid;
  padding: 26px 64px;
  font-size: 20px;
  color: #0b132b;
  font-weight: 600;
  margin: 0;
}

.packageCardLarge .packageCardContainer .MuiCardContent-root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  padding: 0 30px;
  text-align: center;
}
.hidden {
  display: none;
}

.packageCardContainer .MuiCardContent-root .PackageTitle svg{
	color: #4d4d4d !important;
}

.textUpercase{
	text-transform: uppercase !important;
}

.FormDetailsContainerDtl h5{
	margin: 0;
	color: #0B132B;
	font-size: 16px;
	font-weight: 600;
	font-family: 'Poppins', sans-serif !important;
  margin-top: 8px;
}

.BlAddress p{
  color: #0B132B !important;
}

.DatePickerHelperText{
  color: #676C7B;
	font-size: 14px;
	font-weight: 400;
	font-family: 'Poppins', sans-serif !important;
  margin-top: 8px;
}

.DatePickerHelperText span{
  color: #0B132B;
	font-weight: 600;

}

.DatePickerContainer .MuiInput-underline:before {
  display: none;
}

.DatePickerContainer .MuiInput-underline:after {
  display: none;
}

.DatePickerContainer .MuiTextField-root {
  border: #dfe0e9 1px solid;
  border-radius: 4px;
  padding: 9px;
  padding-right: 0;
  padding-left: 15px;
}


 .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.Loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 999;
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Loading .MuiCircularProgress-colorPrimary {
  color: #535456 !important;
}

/*===Tabs Css ==*/

.checkOutTabsContainer {
  margin-left: -8px;
}
.checkOutTabsContainer .MuiTabs-indicator{
display: none;
}


.checkOutTabsContainer .MuiTab-root{
  border: 1px solid #E4E8EC;
  border-radius: 4px;
  margin: 0 8px;
  width: 195px;

  font-weight: 600;
  font-size: 14px;
  color: #1b2121;
  text-transform: initial !important;
}


.checkOutTabsContainer .MuiTab-root.Mui-selected{
  border: 1px solid #56b149;
}

.checkOutTabsContainer .MuiTab-wrapper {
  width: 100% !important;
  display: inline-flex !important;
  align-items: center !important;
  flex-direction: initial !important;
  justify-content: left !important;
}

.checkOutTabsContainer .MuiTab-wrapper .MuiSvgIcon-root{
  margin: 0 !important;
  margin-right: 8px !important;
}

.checkOutTabsContainer .MuiTab-wrapper .TabcheckIcon{
  display: none;
  color: #56b149;
  position: absolute;
  right: 2px;
  top: 10px;
  font-size: 23px;
}

.checkOutTabsContainer .MuiTab-root.Mui-selected .MuiTab-wrapper .TabcheckIcon{
  display: block;
}

.backbutton {
  margin-bottom: 10px !important;
}

.backbutton span{
  color: #56b149;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0;
}

.backbutton span svg {
  color: #56b149;
  font-size: 17px;
}

.input_container .MuiOutlinedInput-input {
  height: 48px !important;
}

.SelectFieldOne .MuiSelect-selectMenu {
  height: 47px !important;

}


.PhoneFieldManContainer .react-tel-input .form-control {
  min-height: 48px!important;

}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0;
}

.formHeaderCtn{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.secureText{
  color: #4C5E67;
  font-size: 14px;
  font-weight: 400;
}

.secureText svg{
  color: #4C5E67;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.3;
  position: relative;
  top: 3px;
}

.formHeaderCtn .MuiButton-text{
  padding: 0;
}

.cashFailedAlrt {
  width: 100%;
  text-align: center;
  z-index: 999;
  position: fixed;
  top: 18px;
  left: 0;
}

.cashFailedAlrt .MuiAlert-standardError {
  border: #FF0033 1px solid;
  align-items: center;
  max-width: 730px;
  margin: 0 auto;
}

.cashFailedAlrt .MuiAlert-standardError .MuiAlert-message{
  color:#0B132B;
  font-size: 17px;
  line-height: 28px;
  text-align: left;
  
}

.FormContainer .AccordionContianer .MuiAccordion-root {
  background: #edf6ff;
  box-shadow: none;
  border: #E4E8EC 1px solid;
  border-radius: 4px;
}

.FormBody .MuiAccordionSummary-root.Mui-expanded {
  border-bottom: 1px solid #E4E8EC !important;
}

.FormGroup .amountSign {
  height: 47px;
  width: 60px;
  right: -2px;
}

.PaymentContainer .MuiBox-root{
  padding: 24px 0 !important;
}


@media (max-width:768px){
  
  .PaymentContainer .MuiBox-root{
    padding: 24px 7px !important;
  }


  .MuiTabs-flexContainer {
    display: block !important;
}

.checkOutTabsContainer .MuiTab-root {
  width: 97%;
  max-width: initial;
  margin-bottom: 18px;
  margin: 5px 16px !important;
}

.checkOutTabsContainer .MuiTab-root:last-child {
  margin-bottom: 0px;
}

.checkOutTabsContainer .MuiTabs-scroller {
  white-space: initial !important;
}

.StapperMainContainer .StepperHeader {
  padding: 0 0px;
}

.MuiStepLabel-root {
  display: block !important;
  align-items: center;
  text-align: center;
}
.MuiStepLabel-iconContainer {
  display: inline-block !important;
  flex-shrink: 0;
  padding-right: 8px;
}

.StapperMainContainer .FormBody {
  margin: 0 auto;
  padding: 0 0px;
  padding-bottom: 30px;
  margin-top: 15px;
  padding-bottom: 170px;
}

.AccordionContianer .MuiAccordionDetails-root {
  padding: 15px;
  background: #fff;
  display: block;
}

.OrderDtllst li {
  margin: 0;
}

.DeleteModalBox {
  width: 92%;
}


.formSectionTitleIn{
  margin-top: 15px;
}


.AccordionContianer .MuiAccordionSummary-root.Mui-expanded {
  min-height: 50px;
  height: 50px;
}






.QuantityField {
  width: 88%;
}

.StapperMainContainer .FormButtonBody .MuiButton-containedPrimary {
  margin: 0 15px;
}

.MuiDrawer-paperAnchorRight {
  width: 280px;
}
.DrawerBody {
  padding: 15px 15px;
}

.MuiDrawer-paper {
  top: 43px;
}

.DrawerTitel {
  padding: 15px 15px;
}

.PaymentformContainer{
  padding: 0 9px;
}

.PaymentformContainer .MuiGrid-grid-xs-12{
  padding: 8px 0px;
}

.checkbox_container{
  margin-top: 20px;
}

}




