@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

/* body{font-family: 'Poppins', sans-serif !important;}

.MuiTypography-body1{
	font-family: 'Poppins', sans-serif !important;
} */
body{ margin: 0; padding: 0;}

.MuiCollapse-wrapperInner li{
	list-style: disc !important;
	margin-left: 30px;
}


.MuiCollapse-wrapperInner ul {
    margin: 0;
    padding-left: 23px;
}

.MuiCollapse-wrapperInner ul li {
    list-style: decimal-leading-zero!important;
    margin-bottom: 30px;
	margin-left: 0  !important;
}

.MuiCollapse-wrapperInner p {
	padding: 0 !important;
	margin: 0 !important;
}

.VideoContainer{
	margin-top: 30px;
}

.factDescription{
	font-size: 40px;
	line-height: 52px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
}

.FooterContainer .MuiGrid-container {
    align-items: flex-start;
}

.MuiTab-root{
    min-height: 57px !important;
}

.MuiAppBar-root .MuiIconButton-label .MuiSvgIcon-root {
    color: #fff !important;
}

@media (max-width: 768px) {
	.FooterMainBoxBottomMainContainer .FooterMainBoxBottomContainer{
		display: block !important;
	}

	.MobileViewContainer{
		padding: 20px;
	}

	.FooterMainBoxBottomMainContainer .FooterMainBoxBottomWhite .FooterMainBoxBottomWhiteText {
		padding-bottom: 12px;
	}
	.MuiContainer-root{
		width: 93% !important;
	}

	.FormBody .MuiContainer-root{
		margin: 0 !important;
		padding: 0 !important;
	}

	.factDescription {
		font-size: 20px;
		line-height: 32px;
	}

	.MuiGrid-item {
		margin: 0 !important;
		box-sizing: border-box;
	}

	.MuiListItem-gutters {
		padding-left: 0;
		padding-right: 16px;
	}
	.MuiTab-root {
		max-width: initial !important;
		width: 100%;
	}

	.MuiExpansionPanelDetails-root {
		display: flex;
		padding: 0;
		padding-bottom: 20px;
	}

	.FormBody .MuiContainer-root{
		width: 100% !important;
	}

	.ProjectContainer .MuiContainer-root{
		width: 100% !important;
	}
}



