 .banner .MuiButton-label {
    font-size: 20px;
}
/*
.ActivityContainer {
    padding: 70px !important;
    background: #F9F8FB url(../../assets/grey_pattren.png) !important;
    margin-top: 80px !important;
}

.ActivityInnerContainer{
    max-width: 1115px;
    margin: 0 auto;
}

.ActivityContainer h2 {
    color: #080F58;
    font-size: 60px;
    font-family: Roboto Slab;
    font-weight: 400;
    margin-bottom: 25px;
    text-align: left;
    margin-top: 0  !important;
}

.ActivityContainer .Act_subtitle {
    color: #00000099;
    display: block;
    font-size: 30px;
    font-family: Roboto Slab;
    line-height: 1.5;
    text-align: left;
}

.ActivityContainer .Act_Description{
    color: #00000099;
    display: block;
    font-size: 20px;
    margin-top: 20px;
    font-family: Open Sans;
    line-height: 1.5;
    padding-right: 30px;
}

.SupportBtn {
    color: #fff !important;
    width: 207px;
    border: 0 !important;
    height: 70px;
    font-size: 20px !important;
    background-color: #00C9A8 !important;
    margin-top: 45px !important;
}

.ActivityInnerContainer .bannerContentsWrapper{
    display: none !important;
}

@media (max-width: 768px) {
    .ActivityContainer {
        padding: 25px !important;
    }
} */


.oursocialmedia{
    background: #FBFAFD;
    padding: 60px 25px;
}

.mainContainer{
    max-width: 1080px;
    margin: 0 auto;
}

.oursocialmedia h1{
    text-align: center;
    color: #080F58;
    margin: 0 0 15px 0;
    font-size: 60px;
    font-family: Roboto Slab;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 50px;
}

.StayCard{
    padding:24px;
    background: url(../../assets/grey_pattren.png);
    border-radius: 4px;
}

.StayCard h2{
    margin: 0;
    font-size: 30px;
    color: #000000;
    font-weight: 400;
    font-family: Roboto Slab;
}

.StayCard p{
    margin: 0;
    font-size: 24px;
    color: #00000099;
    font-weight: 400;
    font-family: Roboto Slab;
    padding: 20px 0;
}


.StayCard a{
    display: flex;
    background:#1877F2;
    color: #fff;
    height: 70px;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 20px;
    border-radius: 4px;
    font-family: Roboto Slab;
}

.StayCard a svg{
    margin-left: 8px;
}